import { Component, CSSProperties, ReactNode } from 'react';
import TitlePageImage, { TitlePageImageProps } from './TitlePageImage';
import getImages from '../../../utility/ImageUtil';

import './TitlePage.css';
import { PageControl } from 'src/components/PageContainer';

const IMAGES_PER_ROW = 10;

export default class TitlePage extends Component<TitlePageProps, TitlePageState> {

    constructor(props : TitlePageProps) {
       super(props); 
       this.state = {
           images: [], // initialize this to blank images so page appears okay while images load
           imageSize: 0,
           rows: 0,
           columns: 0
       };
       this.doSizing = this.doSizing.bind(this);
    }

    render() : ReactNode {

        return (
            <div className='titlepage_container'>
                <div style={this.getContainerStyle()} className="titlepage_imageholder">
                    {
                        this.state.images.map((props, index) => 
                            <TitlePageImage key={`titlepageimg-${index}`}{...props} size={this.state.imageSize}/>
                        )
                    }
                </div>
                <div className='titlepage_container-header_container'>
                    <div className='titlepage_container-header'>
                        <h1>Gabe Mertz</h1>
                        <p>Personal Website</p>
                        <p>(work in progress)</p>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.doSizing();
        window.addEventListener('resize', this.doSizing);
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.doSizing);
    }

    doSizing() {
        
        const container = get('.titlepage_container')! as HTMLDivElement;
        const availableWidth = container.clientWidth;
        const availableHeight = container.clientHeight;

        const imageSize = (Math.max(availableWidth, availableHeight) / (IMAGES_PER_ROW)) * .9;
        const requiredRows = Math.ceil(availableHeight/imageSize);
        const requiredCols = Math.ceil(availableWidth/imageSize);

        const sample = getImages().slice(0, requiredRows * requiredCols);

        this.setState({ 
            images: sample.map(image => ({
                size: imageSize,
                imageUrl: image.path
            })),
            imageSize: imageSize,
            rows: requiredRows,
            columns: requiredCols
        });
    }
    
    getContainerStyle(): CSSProperties {

        if (this.state.imageSize < 1) return {};

        const container = get('.titlepage_container')! as HTMLDivElement;
        const spilloverWidth = container.clientWidth - (this.state.columns * this.state.imageSize);
        const spilloverHeight = container.clientHeight - (this.state.rows * this.state.imageSize);
        return {
                width: `${(this.state.columns) * this.state.imageSize}px`,
                height: `${(this.state.rows) * this.state.imageSize}px`,
                top: `${spilloverHeight/2}px`,
                left: `${spilloverWidth/2}px`,
            };
    }
}

const get = (str: string) => document.querySelector(str);

type TitlePageProps = {
    pageControl: PageControl
}
type TitlePageState = {
    images: TitlePageImageProps[],
    imageSize: number,
    rows: number,
    columns: number
}
