import { CSSProperties } from 'react';

import './TitlePageImage.css';

export default function TitlePageImage(props : TitlePageImageProps) {

    return (
        <div className='title-image_container' style={computeStyle(props)}>
            {  props.imageUrl &&
                <img className='title-image_img' style={computeStyle(props)} src={ props.imageUrl }/>
            }
        </div>
    );
}

const computeStyle = (props : TitlePageImageProps) : CSSProperties => ({
    width: props.size,
    height: props.size,
    maxWidth: props.size,
    maxHeight: props.size
})

export type TitlePageImageProps = {
    size: number,
    imageUrl?: string
}
