import React from 'react';
import PageContainer from './components/PageContainer';

function App() {
  return (
    <PageContainer key={'pagemain'}/>
  );
}

export default App;
