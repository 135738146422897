import { Component, ReactNode } from "react";
import './Page.css';

export default class Page extends Component<PageProps, PageState> {

    constructor(props : PageProps) {
        super(props);
    }

    render() : ReactNode {
        return ( 
            <div className={ `page ${this.props.active ? 'active' : ''}` }>
                {this.props.children}
            </div>
        );
    }
}

type PageProps = {
    children?: React.ReactNode,
    active: boolean
}

type PageState = {}
