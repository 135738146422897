import json from '../configuration/images.json'

const images = shuffle(json).map(correctPath);
export default function getImages() : ImageProperies[] {

    return images;
}

function shuffle(array : any[]) {

    for (let i = array.length-1; i > -1; --i) {

        const randomIndex = Math.floor(Math.random()*i);
        const temp = array[randomIndex];
        array[randomIndex] = array[i];
        array[i] = temp;
    }

    return array;
}

function correctPath(img: ImageProperies) {
    return {
        ...img,
        path: img.path.replace('./public/', '')
    };
}

export type ImageProperies = {
    path: string
}
