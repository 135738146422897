import { Component, ReactNode } from "react";
import './Page.css';
import Page from "./Page";
import TitlePage from './pages/title/TitlePage'

export default class PageContainer extends Component<PageContainerProps, PageContainerState> {
    constructor(props: PageContainerProps) {
       super(props); 
       const pageControl = { 
           setPage: this.setPage.bind(this)
       };
       this.state = {
           currentPage: 0,
           previousPage: -1,
           pages: [
               <TitlePage pageControl={pageControl}/>,
           ]
       };
       this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    render() : ReactNode {
        return (
            <div className="page-container">
                <Page active={ true }>
                    { this.currentPage() }
                </Page>
                <Page active={ false }>
                    { this.previousPage() }
                </Page>
            </div>
        );
    }

    componentDidMount() {
        document.addEventListener('keypress', this.handleKeyPress);
    }

    handleKeyPress(event : KeyboardEvent) { 

            switch (event.key) {
                case 'l':
                    this.setPage(1); 
                    break;
                case 'h':
                    this.setPage(-1);
                    break;
            }
    }

    currentPage() : ReactNode {
        return this.state.pages[this.state.currentPage];
    }

    previousPage() : ReactNode {
        return this.state.previousPage < 0
            ? null
            : this.state.pages[this.state.previousPage];
    }

    setPage(offset: number) {

        const targetIndex = this.state.currentPage + offset;
        if (targetIndex < 0 || targetIndex > this.state.pages.length-1) return;
        this.setState({ currentPage: targetIndex });
    }
}
export type PageControl = {
    setPage: Function
}
type PageContainerProps = {}
type PageContainerState = {
    currentPage: number,
    previousPage: number
    pages: ReactNode[]
}
